var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","dark":"","fullscreen":"","transition":"fade-transition"}},[_c('div',{staticClass:"d-flex align-center justify-center overlay"},[_c('Button',{staticClass:"place-up",attrs:{"absolute":"","top":"","right":"","icon":"","x-large":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-close")])],1),(_vm.mountCarousel)?_c('Carousel',_vm._b({ref:"mediaContainer",staticClass:"w100 h100",model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},'Carousel',
				_vm.$b.props({
					showArrows: { d: true },
					showDots: true,
					noDrag: { d: true, m: _vm.scale > 1 },
					gutter: 0,
				})
			,false),_vm._l((_vm.mediaItems),function(mediaItem,i){return _c('div',_vm._g({key:i,ref:("media-" + i),refInFor:true,staticClass:"img w100 h100",class:{
					scale1: _vm.scale == 1,
					scale2: _vm.scale == 2,
					active: _vm.currentIndex == i,
					'py-8': _vm.$b.d,
					'pt-60': _vm.$b.m,
					'pt-80': _vm.$b.t,
				}},_vm.getHandlers(mediaItem)),[_c('Media',{style:(_vm.currentIndex == i ? _vm.containerStyle : {}),attrs:{"src":!mediaItem._multiple ? mediaItem : mediaItem.big,"height":"100%","width":"100%","scale-down":"","loader":"","video-props":{
						class: 'text-center',
						videoAttrs: {
							controls: true,
							width: _vm.$b.d ? 'auto' : '100%',
							height: _vm.$b.d ? '100%' : 'auto',
						},
					},"youtube-video-props":{
						iframeAttrs: {
							frameborder: 0,
						},
					}}})],1)}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }