<script>
import PinchScrollZoomAxis from './pinch-scroll-zoom-axis'
import { throttle } from 'lodash'

function getDistance(x1, y1, x2, y2) {
	const sqrDistance = (x1 - x2) ** 2 + (y1 - y2) ** 2
	const distance = Math.sqrt(sqrDistance)
	return distance
}

export default {
	props: {
		showDialog: Boolean,
		indexValue: Number,
		mediaItems: Array,
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	data() {
		return {
			currentIndex: this.indexValue,
			mountCarousel: false,
			scale: 1,

			touch1: false,
			touch2: false,
			axisX: null,
			axisY: null,
			minScale: 0.3,
			maxScale: 5,
		}
	},
	mounted() {
		this.axisX = new PinchScrollZoomAxis({
			size: window.innerWidth,
			translate: 0,
		})
		this.axisY = new PinchScrollZoomAxis({
			size: window.innerHeight,
			translate: 0,
		})
	},
	watch: {
		indexValue(value, oldValue) {
			this.currentIndex = value || 0
		},
		currentIndex(value, oldValue) {
			this.$emit('updateIndexValue', value)
			this.stopVideoPlayback(oldValue)
		},
		showDialog(value) {
			setTimeout(() => (this.mountCarousel = value), 50)
		},
	},
	computed: {
		containerStyle() {
			if (this.$b.d) return {}

			const x = `${this.axisX.point}px`
			const y = `${this.axisY.point}px`
			const scale = this.scale < 1 ? 1 : this.scale
			const translate = `translate3d(${x}, ${y}, 0) scale(${scale})`
			const transformOrigin = `${this.axisX.origin}px ${this.axisY.origin}px`

			return {
				transform: translate,
				'transform-origin': transformOrigin,
			}
		},
		activeImgRef() {
			return this.$refs[`media-${this.currentIndex}`][0]
		},
	},
	methods: {
		getHandlers(mediaItem) {
			if (mediaItem.type == 'video') return {}
			if (this.$b.d) return { click: this.toggleScale }
			return {
				touchstart: this.startTouchDrag,
				touchmove: this.doTouchDrag,
			}
		},
		startTouchDrag(touchEvent) {
			touchEvent.preventDefault()
			const touches = Array.from(touchEvent.touches)
			this.startDrag(touches)
		},
		stopDrag() {
			this.touch1 = false
			this.touch2 = false
			this.zoomIn = false
			this.zoomOut = false
		},
		startDrag(touches) {
			if (touches.length === 0) {
				this.stopDrag()
				return
			}
			const clientX1 = this.getBoundingTouchClientX(touches[0].clientX)
			const clientY1 = this.getBoundingTouchClientY(touches[0].clientY)
			if (touches.length > 1) {
				this.touch1 = true
				this.touch2 = true
				this.startScale = this.scale

				const clientX2 = this.getBoundingTouchClientX(touches[1].clientX)
				const clientY2 = this.getBoundingTouchClientY(touches[1].clientY)

				this.axisX.pinch(clientX1, clientX2, this.scale)
				this.axisY.pinch(clientY1, clientY2, this.scale)
			} else {
				this.touch1 = true
				this.touch2 = false
				this.axisX.touch(clientX1)
				this.axisY.touch(clientY1)
			}
		},
		doTouchDrag(touchEvent) {
			touchEvent.preventDefault()
			const touches = Array.from(touchEvent.touches)
			this.throttleDoDrag(touches)
		},
		throttleDoDrag: throttle(function (touches) {
			if (!this.touch1 && !this.touch2) return
			if (touches.length === 0) return

			if (this.touch1 && this.touch2 && touches.length === 1) this.startDrag(touches)

			if (!this.touch1 || (!this.touch2 && touches.length === 2)) this.startDrag(touches)

			if (this.touch1 && this.touch2) {
				this.axisX.dragPinch(
					this.getBoundingTouchClientX(touches[0].clientX),
					this.getBoundingTouchClientX(touches[1].clientX)
				)
				this.axisY.dragPinch(
					this.getBoundingTouchClientY(touches[0].clientY),
					this.getBoundingTouchClientY(touches[1].clientY)
				)
			} else {
				this.axisX.dragTouch(this.getBoundingTouchClientX(touches[0].clientX))
				this.axisY.dragTouch(this.getBoundingTouchClientY(touches[0].clientY))
			}

			this.doScale(touches)
		}, 25),
		checkWithin() {
			this.axisY.checkAndResetToWithin(this.scale)
			this.axisX.checkAndResetToWithin(this.scale)
		},
		doScale(touches) {
			if (touches.length < 2 || !this.touch1 || !this.touch2) {
				this.checkWithin()
				return
			}

			const touch1 = touches[0]
			const touch2 = touches[1]

			const distance = getDistance(
				this.getBoundingTouchClientX(touch1.clientX),
				this.getBoundingTouchClientY(touch1.clientY),
				this.getBoundingTouchClientX(touch2.clientX),
				this.getBoundingTouchClientY(touch2.clientY)
			)

			const startDistance = getDistance(
				this.axisX.start1,
				this.axisY.start1,
				this.axisX.start2,
				this.axisY.start2
			)

			const scale = this.startScale * (distance / startDistance)
			this.submitScale(scale)
		},
		submitScale(scale) {
			if (scale < this.minScale) {
				scale = this.minScale
			}
			if (scale > this.maxScale) {
				scale = this.maxScale
			}
			if (this.scale !== scale) {
				this.zoomIn = this.scale < scale
				this.zoomOut = this.scale > scale
				this.scale = scale
				this.stopScaling()
			}
			this.checkWithin()
		},
		getBoundingTouchClientX(clientX) {
			return clientX - this.activeImgRef.getBoundingClientRect().left
		},
		getBoundingTouchClientY(clientY) {
			return clientY - this.activeImgRef.getBoundingClientRect().top
		},
		stopVideoPlayback(index) {
			const mediaContainer = this.$refs.mediaContainer?.$el
			if (!mediaContainer) return

			const oldSlide = mediaContainer.querySelector(`.img:nth-child(${index + 1}) video`)
			if (oldSlide) {
				// oldSlide.currentTime = 0
				oldSlide.pause()
			}
		},
		closeDialog() {
			this.$emit('update:show-dialog', false)
		},
		toggleScale() {
			this.scale += 1
			if (this.scale > 2) {
				this.scale = 0
			}
		},
	},
}
</script>

<template>
	<v-dialog :value="showDialog" persistent dark fullscreen transition="fade-transition">
		<div class="d-flex align-center justify-center overlay">
			<Button class="place-up" absolute top right icon x-large @click="closeDialog" dark>
				<v-icon x-large>mdi-close</v-icon>
			</Button>
			<Carousel
				ref="mediaContainer"
				v-if="mountCarousel"
				v-model="currentIndex"
				class="w100 h100"
				v-bind="
					$b.props({
						showArrows: { d: true },
						showDots: true,
						noDrag: { d: true, m: scale > 1 },
						gutter: 0,
					})
				"
			>
				<div
					v-for="(mediaItem, i) of mediaItems"
					:key="i"
					class="img w100 h100"
					:class="{
						scale1: scale == 1,
						scale2: scale == 2,
						active: currentIndex == i,
						'py-8': $b.d,
						'pt-60': $b.m,
						'pt-80': $b.t,
					}"
					v-on="getHandlers(mediaItem)"
					:ref="`media-${i}`"
				>
					<Media
						:src="!mediaItem._multiple ? mediaItem : mediaItem.big"
						height="100%"
						width="100%"
						scale-down
						loader
						:style="currentIndex == i ? containerStyle : {}"
						:video-props="{
							class: 'text-center',
							videoAttrs: {
								controls: true,
								width: $b.d ? 'auto' : '100%',
								height: $b.d ? '100%' : 'auto',
							},
						}"
						:youtube-video-props="{
							iframeAttrs: {
								frameborder: 0,
							},
						}"
					/>
				</div>
			</Carousel>
		</div>
	</v-dialog>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.place-up {
	z-index: 999;
}
.overlay {
	background-color: rgba(0, 0, 0, 0.8);
	height: 100%;
}
.img {
	transform-origin: center;
	transition: 0.3s all;
}
.img.active.scale1 {
	transform: scale(1.2);
}
.img.active.scale2 {
	transform: scale(1.4);
}

.pt-80 {
	padding-top: 80px !important;
}

.pt-60 {
	padding-top: 60px !important;
}

::v-deep div.ssr-carousel,
::v-deep div.ssr-carousel-slides,
::v-deep div.ssr-carousel-mask,
::v-deep div.ssr-carousel-track {
	height: 90vh;
}

::v-deep .ratio-box__slot {
	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

::v-deep button.ssr-carousel {
	@media (min-width: 900px) {
		&-next-button {
			right: 2%;
		}
		&-back-button {
			left: 2%;
		}
	}
	// &-next-button {
	// 	@media (min-width: 900px) {
	// 		right: 2%;
	// 	}
	// }
	// &-back-button {
	// 	@media (min-width: 900px) {
	// 		left: 2%;
	// 	}
	// }
}

// @media (min-width: 900px) {
// 	::v-deep button.ssr-carousel-next-button {
// 		right: 2%;
// 	}
// 	::v-deep button.ssr-carousel-back-button {
// 		left: 2%;
// 	}
// }
</style>

