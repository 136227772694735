class PinchScrollZoomAxis {
	_point = 0
	_start1 = 0
	_start2 = 0
	_origin
	_size
	_contentSize

	constructor(props) {
		this._origin = props.origin ?? props.size / 2
		this._size = props.size
		this._point = props.translate
		this._contentSize = props.contentSize
	}

	get point() {
		return this._point
	}

	get origin() {
		return this._origin
	}

	get start1() {
		return this._start1
	}

	get start2() {
		return this._start2
	}

	touch(point) {
		this._start1 = point - this._point
	}

	pinch(point1, point2, scale) {
		const prevOrigin = this._origin
		const midpoint = (point1 + point2) / 2
		this._origin = prevOrigin + (midpoint - prevOrigin - this._point) / scale
		const delta = prevOrigin - this._origin

		this._point += delta - delta * scale
		this._start1 = point1 - this._point
		this._start2 = point2 - this._point
	}

	dragPinch(point1, point2) {
		const start = (this._start1 + this._start2) / 2
		const point = (point1 + point2) / 2
		this._point = point - start
	}

	dragTouch(point) {
		this._point = point - this._start1
	}

	checkAndResetToWithin(scale) {
		const contentSize = this._contentSize ?? this._size
		if (contentSize * scale < this._size) {
			this._origin = this._size / 2
			this._point = ((this._size - contentSize) * scale) / 2
			return
		}

		const overflow = (contentSize - this._size) * scale
		const border = (this._size - this._size * scale) / 2

		const originFactor = this._size / 2 - this._origin - (this._size / 2 - this._origin) * scale
		if (this._point > originFactor - border) {
			this._point = originFactor - border
		} else if (this._point < border + originFactor - overflow) {
			this._point = border + originFactor - overflow
		}
	}

	setPoint(point) {
		this._point = point
	}

	setOrigin(origin) {
		this._origin = origin
	}
}

module.exports = PinchScrollZoomAxis

