<script>
export default {
	props: {
		items: Array,
	},
	data() {
		return {
			tab: null,
		}
	},
	methods: {
		hasHtml(content) {
			const htmlRegex = /<("[^"]*"|'[^']*'|[^'">])*>/
			return htmlRegex.test(content)
		},
	},
}
</script>

<template>
	<div :class="$bem('product-page-infotabs')">
		<h2 class="font-3 pl-2 my-4 font-weight-bold" :class="$bem('__title')">Información Adicional</h2>
		<div class="shadow">
			<v-tabs
				:class="$bem('__tabs')"
				v-model="tab"
				:background-color="$vars.secondary"
				:color="$vars.secondarytext"
				active-class="active-tab"
				hide-slider
				center-active
			>
				<v-tab
					v-for="(item, i) in items"
					:key="i"
					class="font-2 font-weight-bold"
					style="text-transform: none"
					:class="$bem('__tab')"
				>
					<div class="base" :class="$bem('__tab__content')">
						<div class="d-flex justify-center" :class="$bem('__tab__image__container')">
							<Media v-if="item.img" :src="item.img" :class="$bem('__tab__image')" />
						</div>
						<h3 v-if="item.title" :class="$bem('__tab__title')">
							{{ item.title }}
						</h3>
					</div>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab" :class="$bem('__tab-items')">
				<v-tab-item eager v-for="(item, i) in items" :key="i" :class="$bem('__tab-item')">
					<v-card flat :class="$bem('__tab-item__content__container')">
						<div
							v-if="item.useRichText"
							v-html="item.richDescription"
							class="px-6 py-8"
							:class="$bem('__tab-item__content')"
						></div>
						<div
							v-else
							class="font-1 px-6 py-8"
							:class="[{ 'pre-line': !hasHtml(item.content) }, $bem('__tab-item__content')]"
							v-html="item.content"
						></div>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<style lang="scss" scoped>
::v-deep .v-tabs-bar {
	height: auto;
}

::v-deep .v-application .white--text {
	color: unset !important;
}

.pre-line {
	white-space: pre-line;
}
// ::v-deep
// 	.v-tabs:not(.v-tabs--vertical).v-tabs--right
// 	> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
// 	.v-slide-group__next {
// 	display: contents;
// }

::v-deep .v-item-group {
	border-radius: 5px 5px 0px 0px;
}

::v-deep .active-tab {
	background: var(--base);
	border-top: 3px solid var(--offer);
	color: var(--basetext);
}

::v-deep .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: var(--secondary--text) !important;
}
</style>

