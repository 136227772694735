<script>
export default {
	props: {
		selectedVariantId: String,
		currency: String,
	},
	data() {
		return {
			loading: false,
			zipcode: null,
			options: null,
			error: false,
		}
	},
	watch: {
		zipcode() {
			this.options = null
		},
	},
	methods: {
		calculate() {
			if (!this.zipcode) return

			this.error = false
			this.options = null

			this.$shopApi.post({
				url: '/simulation/delivery/calculate',
				data: {
					items: [{ refId: this.selectedVariantId, refType: 'product', qty: 1 }],
					zipcode: this.zipcode,
				},
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					window.localStorage.setItem('zipcode', this.zipcode)
					this.options = data.sellersOptions[0].options
				},
				onError: () => {
					this.error = true
				},
			})
		},
	},
	mounted() {
		this.zipcode = Number(window.localStorage.getItem('zipcode')) || null
	},
	cssVars: {
		selector: '.product-page__shipment-calculator',
	},
}
</script>

<template>
	<div class="product-page__shipment-calculator py-2">
		<div class="pb-2">
			<IconTextItem :item="{ text: 'Calcular envío', icon: 'mdi-truck-fast-outline' }" />
		</div>
		<div class="d-flex align-center">
			<v-text-field
				v-model.number="zipcode"
				class="zipcode-input mr-2"
				type="number"
				id="zipcode"
				label="Código postal"
				@keydown.enter="calculate()"
				hide-details
				color="black"
				outlined
				dense
			/>
			<Button :disabled="!zipcode" color="link" :loading="loading" @click="calculate()"> CALCULAR </Button>
		</div>
		<div v-if="error" class="grey lighten-4 font-0 pa-2 rounded-lg error--text mt-4">
			Ocurrió un error, intentalo nuevamente
		</div>
		<ProductPage-DeliveryCalc-Results
			v-else-if="options"
			v-bind="{ currency, options, zipcode }"
			class="mt-4"
		/>
	</div>
</template>

<style scoped lang="scss">
.zipcode-input {
	max-width: 200px;
}
/* Chrome, Safari, Edge, Opera */
.zipcode-input ::v-deep input::-webkit-outer-spin-button,
.zipcode-input ::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.zipcode-input ::v-deep input[type='number'] {
	-moz-appearance: textfield;
}
</style>

